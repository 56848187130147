import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import './App.css';

function App() {
  const [match, setMatch] = useState<boolean>(false);
  const mapRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);
  const [navlink, setNavlink] = useState<string>('');
  const [map, setMap] = useState<google.maps.Map>();
  const [directionsService] = useState<google.maps.DirectionsService>(
    new google.maps.DirectionsService()
  );
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer>();

  useEffect(() => {
    if (mapRef.current) {
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const map = new google.maps.Map(mapRef.current, {
        zoom: 6,
        center: { lat: 41.85, lng: -87.65 },
      });

      directionsRenderer.setMap(map);
      setDirectionsRenderer(directionsRenderer);
      setMap(map);
    }
  }, [match]);

  function handleAddressChange(event: ChangeEvent<HTMLTextAreaElement>) {
    const rawAddr = event.target.value as string;
    if (rawAddr === '') {
      setNavlink('');
      // if (mapRef.current)
      //   directionsRenderer?.setMap(
      //     new google.maps.Map(mapRef.current, {
      //       zoom: 6,
      //       center: { lat: 41.85, lng: -87.65 },
      //     })
      //   );
    }
    const addrList = rawAddr.split('\n');
    if (addrList.length > 0) {
      renderDirection(addrList);
    }
  }

  function renderDirection(addresses: string[] = []) {
    const waypts: google.maps.DirectionsWaypoint[] = [];
    for (let index = 1; index < addresses.length - 1; index++) {
      const stop = addresses[index];
      waypts.push({
        location: stop,
        stopover: true,
      });
    }
    directionsService
      .route({
        origin: addresses[0],
        destination: addresses[addresses.length - 1],
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer?.setDirections(response);

        setNavlink(
          `https://www.google.com/maps/dir/?api=1&query=${encodeURIComponent(
            addresses[0]
          )}&origin=${encodeURIComponent(
            addresses[0]
          )}&destination=${encodeURIComponent(
            addresses[addresses.length - 1]
          )}&travelmode=driving&waypoints=${encodeURIComponent(
            response.routes[0].legs.map((x) => x.end_address).join('|')
          )}`
        );

        if (summaryRef.current) {
          const route = response.routes[0];
          const summaryPanel = summaryRef.current;

          summaryPanel.innerHTML = '';

          // For each route, display summary information.
          for (let i = 0; i < route.legs.length; i++) {
            const routeSegment = i + 1;

            summaryPanel.innerHTML +=
              '<b>Route Segment: ' + routeSegment + '</b><br>';
            summaryPanel.innerHTML +=
              route.legs[i].start_address + ' <b> TO </b> ';
            summaryPanel.innerHTML += route.legs[i].end_address + '<br>';
            summaryPanel.innerHTML += route.legs[i].distance!.text + '<br><br>';
          }
        }
      });
  }

  function SecurityCode() {
    return (
      <div className='App'>
        <input
          className='match'
          onChange={(e) => {
            const value = e.target.value;
            if (
              btoa(value) === 'YTI3OTIwMTItYTA3ZC00OWUyLThkOTEtYjY3YTFkMjEzMWE5'
            ) {
              setMatch(true);
            }
          }}
        />
      </div>
    );
  }

  return !match ? (
    <SecurityCode />
  ) : (
    <div className='App'>
      {navlink && (
        <a className='navlink' href={navlink} target='_blank' rel='noreferrer'>
          Navigation Link
        </a>
      )}
      <textarea className='address' onChange={handleAddressChange} />
      <div className='map' ref={mapRef} id='map'></div>
      <div className='summary' ref={summaryRef} id='summary-panel' />
    </div>
  );
}

export default App;
